<template>
  <div class="application-control">
    <b-row>
      <b-col md="4">
        <table>
          <tr>
            <td class="pr-5"><b>Дата</b></td>
            <td>
              {{ inputVal.created | dateFilter }}
            </td>
          </tr>
          <tr>
            <td class="pr-5"><b>Клиент</b></td>
            <td>
              <router-link
                :to="{ name: 'client-view', params: { id: inputVal.client } }"
              >
                {{ inputVal.name }}
              </router-link>
            </td>
          </tr>
          <tr>
            <td class="pr-5"><b>Телефон</b></td>
            <td>
              <a :href="`tel:${inputVal.phone}`">{{ inputVal.phone }}</a>
            </td>
          </tr>
          <tr>
            <td class="pr-5"><b>Сумма</b></td>
            <td>
              {{ inputVal.preferable_amount }}
            </td>
          </tr>
          <tr>
            <td class="pr-5"><b>Срок</b></td>
            <td>
              {{ inputVal.duration_months }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col md="4">
        <table>
          <tr>
            <td class="pr-5"><b>Марка Авто</b></td>
            <td>
              {{ inputVal.car_mark }}
            </td>
          </tr>
          <tr>
            <td class="pr-5"><b>Модель Авто</b></td>
            <td>
              {{ inputVal.car_model }}
            </td>
          </tr>
          <tr>
            <td class="pr-5"><b>Год Авто</b></td>
            <td>
              {{ inputVal.year }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col class="text-right">
        <b-dropdown
          :variant="statusColor"
          :text="inputVal.status_display"
          :disabled="inputVal.has_deal"
        >
          <b-dropdown-item
            v-if="inputVal.status <= 1"
            v-b-modal.declineModal
            variant="danger"
            >Отклонить</b-dropdown-item
          >
          <b-dropdown-item
            v-if="inputVal.status === 0"
            variant="success"
            @click="changeStatus('ready')"
            >Завершить заполнение</b-dropdown-item
          >
          <b-dropdown-item
            v-if="inputVal.status === 1"
            variant="success"
            @click="changeStatus('confirm')"
            >Согласовать</b-dropdown-item
          >
          <b-dropdown-item
            v-if="inputVal.status === 3 && !inputVal.has_deal"
            variant="success"
            @click="createDeal"
          >
            Создать сделку
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-modal
      id="declineModal"
      title="Вы уверены?"
      @ok="decline"
      @cancel="inputVal.decline_comment = ''"
    >
      <b-form>
        Укажите причину отклонения
        <b-textarea v-model="inputVal.decline_comment" />
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ApplicationControl',
  props: ['value'],
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    statusColor() {
      switch (this.inputVal.status) {
        case 1:
          return 'outline-warning'
        case 2:
          return 'outline-danger'
        case 3:
          return 'outline-success'
        default:
          return 'outline-primary'
      }
    },
  },
  methods: {
    async changeStatus(status) {
      let text = ''
      let textBtn = ''
      if (status === 'confirm') {
        this.inputVal.status = 3
        text = 'Вы уверены, что хотите согласовать заявку?'
        textBtn = 'Согласовать'
      } else if (status === 'ready') {
        this.inputVal.status = 1
        text = 'Вы уверены, что хотите завершить заполнение?'
        textBtn = 'Завершить'
      }
      this.$bvModal
        .msgBoxConfirm(text, {
          title: 'Необходимо подтверждение',
          size: 'sm',
          okVariant: 'primary',
          okTitle: textBtn,
          cancelTitle: 'Закрыть',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.$emit('updateApplication')
          } else {
            this.inputVal.status = 1
          }
        })
    },
    async createDeal() {
      try {
        const deal = {
          application: this.inputVal.id,
          value: this.inputVal.preferable_amount,
          period: this.inputVal.duration_months,
          rate: this.inputVal.rate,
        }
        const res = await this.$http.post('admin/deals/', deal)
        this.$router.push({ name: 'deal-view', params: { id: res.data.id } })
      } catch (e) {
        this.$toast.danger('Ошибка')
      }
    },
    async decline() {
      this.inputVal.status = 2
      this.$emit('updateApplication')
    },
  },
}
</script>
