<template>
  <b-row>
    <b-col>
      <b-card>
        <b-overlay :show="loading">
          <b-table
            :fields="fields"
            :items="docs"
            empty-text="Нет данных"
            show-empty
            responsive
            @row-clicked="openSidebar"
          >
            <template #cell(is_identified)="data">
              <template v-if="data.value">
                <feather-icon class="text-success" icon="CheckIcon" size="24" />
              </template>
              <template v-else>
                <feather-icon class="text-danger" icon="XIcon" size="24" />
              </template>
            </template>
          </b-table>
        </b-overlay>
      </b-card>
      <b-row v-if="!clientMode">
        <b-col md="4">
          <b-card>
            <b-button block variant="primary" @click="openCreateDoc">
              Добавить
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-sidebar
      id="docSidebar"
      ref="docSidebar"
      backdrop
      bg-variant="white"
      right
      width="70%"
      @hidden="selectedDoc = { doc_photos: [] }"
    >
      <b-row class="p-2">
        <b-col md="4">
          <b-row>
            <b-col md="4">
              <b-form-group label="ID">
                <b-form-input v-model="selectedDoc.id" disabled />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group label="Тип документа">
                <b-form-select
                  v-model="selectedDoc.document_type"
                  :options="docTypes"
                  text-field="display_name"
                  value-field="value"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Номер паспорта">
                <b-form-input v-model="selectedDoc.document_number" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Дата выдачи">
                <b-form-input v-model="selectedDoc.issue_date" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Кем выдан">
                <b-form-input v-model="selectedDoc.issued_by" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Срок действия">
                <b-form-input v-model="selectedDoc.valid_till" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Адрес прописки">
                <b-form-textarea v-model="selectedDoc.assigned_address" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-checkbox
                v-model="selectedDoc.is_identified"
                inline
                switch
              >
                Идентифицирован?
              </b-form-checkbox>
            </b-col>
            <b-col class="mt-2" md="12">
              <b-button variant="primary" @click="docUpdate">
                Обновить
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <h3>Фото документа</h3>
            </b-col>
            <b-col class="text-right">
              <b-form-file
                id="docFileUpload"
                v-model="docPhoto"
                class="d-none"
                plain
                @input="uploadDoc"
              />
              <b-button variant="primary" @click="showUpload">
                Загрузить
              </b-button>
            </b-col>
          </b-row>
          <b-carousel
            v-if="selectedDoc.doc_photos.length"
            class="mt-2"
            controls
            indicators
          >
            <template v-for="photo in selectedDoc.doc_photos">
              <b-carousel-slide :key="photo.id" :img-src="photo.photo" />
            </template>
          </b-carousel>
        </b-col>
      </b-row>
    </b-sidebar>
  </b-row>
</template>

<script>
export default {
  name: 'ApplicationClientDocsCard',
  props: ['id', 'clientMode'],
  data() {
    return {
      docs: [],
      docTypes: [],
      selectedDoc: {
        doc_photos: [],
      },
      docPhoto: null,
      loading: false,
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'document_type_display',
          label: 'Тип документа',
        },
        {
          key: 'document_number',
          label: 'Номер документа',
        },
        {
          key: 'valid_till',
          label: 'Срок действия',
        },
        {
          key: 'is_identified',
          label: 'Идентифицирован',
        },
      ],
    }
  },
  watch: {
    id() {
      this.getDocs()
    },
  },
  created() {
    this.getDocs()
    this.getDocOptions()
  },
  methods: {
    async getDocs() {
      this.loading = true
      const res = await this.$http.get(`admin/docs/?user=${this.id}`)
      this.docs = res.data.results
      if (this.selectedDoc.id) {
        const doc = this.docs.findIndex(
          (item) => item.id === this.selectedDoc.id
        )
        this.selectedDoc = this.docs[doc]
      }
      this.loading = false
    },
    async openSidebar(row) {
      this.selectedDoc = row
      this.$root.$emit('bv::toggle::collapse', 'docSidebar')
    },
    async getDocOptions() {
      const res = await this.$http.options('admin/docs/')
      this.docTypes = res.data.actions.POST.document_type.choices
    },
    showUpload() {
      document.getElementById('docFileUpload').click()
    },
    async uploadDoc() {
      try {
        const formData = new FormData()
        formData.append('photo_type', 4)
        formData.append('document', this.selectedDoc.id)
        formData.append('photo', this.docPhoto)
        await this.$http({
          method: 'POST',
          url: 'docs/photo/',
          data: formData,
          config: { headers: { 'content-type': 'multipart/form-data' } },
        })
        await this.getDocs()
        this.$root.$emit('bv::toggle::collapse', 'docSidebar')
        this.$toast.success('Загружено')
      } catch (e) {
        this.$toast.error('Ошибка')
      }
    },
    async docUpdate() {
      try {
        const res = await this.$http.patch(
          `admin/docs/${this.selectedDoc.id}/`,
          this.selectedDoc
        )
        this.selectedDoc = res.data
        await this.getDocs()
        this.$toast.success('Обновлено')
      } catch (e) {
        this.$toast.error('Ошибка')
      }
    },
    openCreateDoc() {
      this.$router.push({
        name: 'application-add-doc',
        params: { id: this.id },
      })
    },
  },
}
</script>

<style lang="scss">
.b-sidebar-body {
  overflow: hidden;
}
</style>
