<template>
  <b-row>
    <b-col md="8">
      <b-card>
        <b-overlay :show="loading">
          <b-table
            :items="relatives"
            :fields="fields"
            responsive
            @row-clicked="(row) => (relative = row)"
          />
        </b-overlay>
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card>
        <b-form-group label="Фамилия">
          <b-form-input v-model="relative.last_name" />
        </b-form-group>
        <b-form-group label="Имя">
          <b-form-input v-model="relative.first_name" />
        </b-form-group>
        <b-form-group label="Отчество">
          <b-form-input v-model="relative.middle_name" />
        </b-form-group>
        <b-form-group label="Степень родства">
          <b-form-input v-model="relative.close_level" />
        </b-form-group>
        <b-form-group label="Телефон">
          <b-form-input v-model="relative.phone" />
        </b-form-group>
        <template v-if="relative.id">
          <b-button-group>
            <b-button @click="updateRelative">Обновить</b-button>
            <b-button variant="danger" @click="deleteRelative"
              >Удалить</b-button
            >
          </b-button-group>
        </template>
        <b-button v-else @click="addRelative"> Добавить </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ApplicationRelativesCard',
  props: ['user'],
  data() {
    return {
      relatives: [],
      fields: [
        {
          key: 'last_name',
          label: 'Фамилия',
        },
        {
          key: 'first_name',
          label: 'Имя',
        },
        {
          key: 'middle_name',
          label: 'Отчество',
        },
        {
          key: 'close_level',
          label: 'Кто ему',
        },
        {
          key: 'phone',
          label: 'Телефон',
        },
      ],
      relative: {
        user: this.user,
      },
      loading: false,
    }
  },
  created() {
    this.getRelatives()
  },
  methods: {
    async getRelatives() {
      this.loading = true
      const res = await this.$http.get('admin/relatives/', {
        params: { user: this.user },
      })
      this.relatives = res.data.results
      this.loading = false
    },
    async addRelative() {
      await this.$http.post('admin/relatives/', this.relative)
      await this.getRelatives()
      this.relative = {}
    },
    async updateRelative() {
      await this.$http.patch(
        `admin/relatives/${this.relative.id}/`,
        this.relative
      )
      await this.getRelatives()
      this.relative = {}
    },
    async deleteRelative() {
      await this.$http.delete(`admin/relatives/${this.relative.id}/`)
      await this.getRelatives()
      this.relative = {}
    },
  },
}
</script>
