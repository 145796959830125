<template>
  <b-row>
    <b-col md="4">
      <b-card>
        <b-form-checkbox v-model="docFines" switch inline class="d-block"
          >Наличие исполнительных документов</b-form-checkbox
        >
        <b-form-group
          v-if="docFines"
          label="Сумма исполнтиельного"
          class="mt-2 w-50"
        >
          <b-form-input v-model="inputVal.legal_debt" />
        </b-form-group>
        <b-form-checkbox
          v-model="creditDelay"
          switch
          inline
          class="d-block mt-2"
          >Наличие текущей просрочки</b-form-checkbox
        >
        <b-form-group
          v-if="creditDelay"
          label="Сумма просрочки"
          class="mt-2 w-50"
        >
          <b-form-input v-model="inputVal.credit_debt" />
        </b-form-group>
        <b-form-checkbox
          v-model="inputVal.has_ip"
          switch
          inline
          class="d-block mt-2"
          >Наличие ИП</b-form-checkbox
        >
        <b-form-group label="Комментарии" class="mt-2">
          <b-form-textarea v-model="inputVal.comment" />
        </b-form-group>
        <b-form-group label="Компания брокера">
          <b-form-select
            v-model="brokerCompany"
            :options="brokerCompanies"
            value-field="id"
            text-field="name"
          />
        </b-form-group>
        <b-form-group label="Брокер">
          <b-form-select
            v-model="inputVal.broker"
            :disabled="!brokerCompany"
            :options="brokers"
            value-field="id"
            text-field="last_name"
          />
        </b-form-group>
        <b-button
          variant="primary"
          class="mt-4"
          @click="$emit('updateApplication')"
        >
          Обновить
        </b-button>
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card>
        <b-form-checkbox v-model="autoPledge" switch inline class="d-block mt-2"
          >Авто в залоге?</b-form-checkbox
        >
        <b-form-checkbox
          v-model="inputVal.has_osgovts"
          switch
          inline
          class="d-block mt-2"
          >Наличие ОСГОВТС</b-form-checkbox
        >
        <b-form-checkbox
          v-model="inputVal.has_to_ts"
          switch
          inline
          class="d-block mt-2"
          >Наличие ТО ТС</b-form-checkbox
        >
        <b-form-checkbox
          v-model="inputVal.searching_mvd"
          switch
          inline
          class="d-block mt-2"
          >Наличие розыска МВД РБ</b-form-checkbox
        >
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card>
        <a
          href="https://minjust.gov.by/directions/enforcement/debtors/"
          target="_blank"
          >Реестр задолженности по исполнительным</a
        ><br />
        <a href="https://vin.kufar.by" target="_blank">Проверка авто Kufar</a
        ><br />
        <a href="https://av.by" target="_blank">Стоимость авто AV.by</a><br />
        <a href="https://ab.onliner.by/" target="_blank"
          >Стоимость авто Onliner.by</a
        ><br />
        <a href="https://osgovts.btib.org/ords/buro/f?p=500:1" target="_blank"
          >Проверка ОСГОВТС</a
        ><br />
        <a href="https://gto.by/services/permission-check/" target="_blank"
          >Проверка ТО ТС</a
        ><br />
        <a
          href="https://autobot.by/search?order=created_at%3Adesc&limit=100&period=36500&page=1"
          target="_blank"
          >Проверка по Автоботу</a
        ><br />
        <a href="https://www.mvd.gov.by/ru/service/6" target="_blank"
          >Проверка авто МВД РБ</a
        ><br />
        <a href="https://blank.bisc.by/" target="_blank"
          >Проверка счет-справки</a
        ><br />
        <a href="https://www.reestr-zalogov.ru/search/index" target="_blank"
          >Проверка авто по реестру РФ</a
        ><br />
        <a
          href="https://xn--90adear.xn--p1ai/check/auto/#XW8ZZZ7LZ9G000062"
          target="_blank"
          >Проверка ГИБДД РФ</a
        ><br />
        <a href="https://vin01.ru/" target="_blank">Проверка авто по VIN</a>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ApplicationCheckListCard',
  props: ['value'],
  data() {
    return {
      docFines: false,
      creditDelay: false,
      autoPledge: false,
      brokerCompanies: [],
      brokers: [],
      brokerCompany: null,
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    autoPledge(newVal) {
      if (newVal === true) {
        this.$bvModal
          .msgBoxConfirm('Если авто в залоге, необходимо отклонить заявку', {
            title: 'Необходимо подтверждение',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Отклонить',
            cancelTitle: 'Закрыть',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value === true) {
              this.inputVal.status = 2
              this.inputVal.comment += ' Авто в залоге!'
              this.$emit('updateApplication')
            } else {
              this.autoPledge = false
            }
          })
      }
    },
    brokerCompany() {
      this.getBrokers()
    },
  },
  created() {
    if (this.inputVal.legal_debt > 0) {
      this.docFines = true
    }
    if (this.inputVal.credit_debt > 0) {
      this.creditDelay = true
    }
    this.getBrokerCompanies()
    if (this.inputVal.broker) {
      this.getBrokers()
    }
  },
  methods: {
    async getBrokerCompanies() {
      const res = await this.$http.get('admin/broker-companies')
      this.brokerCompanies = res.data.results
    },
    async getBrokers() {
      const res = await this.$http.get('admin/brokers/', {
        params: { company: this.brokerCompany },
      })
      this.brokers = res.data.results
    },
  },
}
</script>
