<template>
  <b-row>
    <b-col md="4">
      <b-card>
        <b-form-group label="Сумма, BYN">
          <b-form-input v-model="inputVal.preferable_amount" />
        </b-form-group>
        <b-form-group label="Срок, месяцев">
          <b-form-input v-model="inputVal.duration_months" />
        </b-form-group>
        <b-form-group label="Ставка, в год">
          <b-form-select
            v-model="inputVal.rate"
            :options="terms"
            text-field="title"
            value-field="value"
          />
        </b-form-group>
        <b-form-group label="Ставка, в месяц">
          <b-form-input v-model="rateMonth" disabled />
        </b-form-group>
        <b-form-group label="Ставка, в день">
          <b-form-input v-model="rateDay" disabled />
        </b-form-group>
        <b-button variant="primary" @click="$emit('updateApplication')">
          Обновить
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ApplicationClientTerms',
  props: ['value'],
  data() {
    return {
      terms: [
        { title: 'До 3 мес, 5000, 120.45%', value: 1.2045 },
        { title: 'До 3 мес, 100000, 109.5%', value: 1.095 },
        { title: 'До 24 мес, 30000, 102.2%', value: 1.022 },
        { title: 'До 24 мес, 60000, 91.25%', value: 0.9125 },
        { title: 'До 24 мес, 100000, 65.7%', value: 0.657 },
      ],
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    rateMonth() {
      return this.inputVal.rate / 12
    },
    rateDay() {
      return this.inputVal.rate / 365
    },
  },
}
</script>
